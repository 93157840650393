
import { Component, Vue } from 'nuxt-property-decorator'
import AlaoLogo from '~/assets/images/icons/alao-logo.svg?inline'
import AlaoSmileGray from '~/assets/images/reasons-why-alao/alao-smile-gray.svg?inline'

@Component({
  components: {
    AlaoLogo,
    AlaoSmileGray,
  },
})
export default class AlaoComparison extends Vue {

}
