
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { OfferBenefitsUtils } from '~/utils/offer-benefits.utils'
import BenefitsMobile from '~/components/offers/benefits/BenefitsMobile.vue'
import BenefitsHome from '~/components/offers/benefits/BenefitsHome.vue'
import { OfferCommonUtils } from '~/utils/offer/offer-common.utils'
import { OfferTypeByShortName } from '~/core/offer.types'

enum CardType {
  DEFAULT = 'default',
  EXCLUSIVE = 'exclusive',
}

@Component({
  components: {
    BenefitsHome,
    BenefitsMobile,
  },
})
export default class SpecialOfferCard extends Vue {
  @Prop({ required: true, type: Object }) readonly offer!: any
  @Prop({ default: CardType.DEFAULT }) readonly type!: CardType
  @Prop({ default: true }) readonly isAlaoPlus!: boolean

  private offerBenefitsUtils = new OfferBenefitsUtils(this.offer, this.$i18n, this.$i18n.locale)
  private offerCommonUtils = new OfferCommonUtils(this.offer)
  private OFFER_TYPE = Object.freeze(CardType)

  get offerTitle () {
    if (this.offerBenefitsUtils.isBundle) {
      return
    }

    const { locale } = this.$i18n
    const key = `title_${locale}`

    if (this.offerBenefitsUtils.isMobile || this.offerBenefitsUtils.isDevice) {
      return this.offer.mobile_offer[key] || this.offer.mobile_offer.title_de || ''
    }

    return this.offer.home_offer[key] || this.offer.home_offer.title_de || ''
  }

  get providerLogo () {
    if (this.offerCommonUtils.isMobile || this.offerCommonUtils.isBundle || this.offerCommonUtils.isDevice) {
      return this.offer.mobile_offer.provider.logo
    }
    if (this.offerCommonUtils.isMobile || this.offerCommonUtils.isHome || this.offerCommonUtils.isDevice) {
      return this.offer.home_offer.provider.logo
    }
    return ''
  }

  get deviceName () {
    if (!this.offerCommonUtils.isDevice) {
      return ''
    }
    const { device } = this.offer.device_offer
    return `${device.model.brand.name} ${device.model.name} ${device.gb_memory}GB`
  }

  get deviceImage () {
    return this.offer.device_offer?.color?.images[0]?.file_url ?? ''
  }

  get offerType () {
    if (this.offerBenefitsUtils.isMobile) {
      return OfferTypeByShortName.mobile
    }
    if (this.offerBenefitsUtils.isHome) {
      return OfferTypeByShortName.home
    }
    if (this.offerBenefitsUtils.isBundle) {
      return OfferTypeByShortName.bundle
    }
    if (this.offerBenefitsUtils.isDevice) {
      return OfferTypeByShortName.smartphone
    }
    return ''
  }

  get offerId () {
    if (this.offerBenefitsUtils.isMobile) {
      return this.offer.mobile_offer.pk
    }
    if (this.offerBenefitsUtils.isHome) {
      return this.offer.home_offer.pk
    }
    return this.offer.pk ?? ''
  }

  get offerLink () {
    const query: Record<string, any> = {
      id: this.offerId,
      type: this.offerType,
    }
    return this.localePath({ name: 'order', query })
  }

  get mobileActivationDiscounted () {
    return this.offerCommonUtils.getDiscountedActivationCostMobile(false)
  }

  get homeActivationDiscounted () {
    return this.offerCommonUtils.getDiscountedActivationCostHome(false)
  }

  get buttonSize () {
    if (this.type === this.OFFER_TYPE.EXCLUSIVE) {
      if (this.$mqUtils.isXS || this.$mqUtils.isSM) {
        return 'sm'
      }
      return 'xl'
    }
    if (this.$mqUtils.isXS) {
      return 'sm'
    }
    return 'lg'
  }

  onCTAClick () {
    location.href = this.offerLink
  }
}
