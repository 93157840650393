
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Swiper, SwiperCore, SwiperSlide } from 'swiper-vue2'
import { Pagination } from 'swiper'
import { HomeOffer, MobileOffer } from '@alao-frontend/core'
import Loader from '~/components/Loader.vue'
import SpecialOfferCard from '~/components/black-friday/SpecialOfferCard.vue'
import 'swiper/swiper-bundle.css'
import OfferCard from '~/components/offers/OfferCard/OfferCard.vue'

SwiperCore.use([Pagination])
@Component({
  components: {
    SpecialOfferCard,
    Swiper,
    SwiperSlide,
    Loader,
    OfferCard,
  },
})
export default class OfferSlider extends Vue {
  @Prop({ type: Boolean, default: true, required: false })
  readonly isLoading!: boolean

  @Prop({ type: Array })
  readonly offers!: MobileOffer[] | HomeOffer[]

  get slidePerViewForOffers () {
    return 1
  }

  nextSlide () {
    const offerSlider = this.$refs.offerSlider as any

    offerSlider.$el.swiper.slideNext()
  }

  prevSlide () {
    const offerSlider = this.$refs.offerSlider as any
    offerSlider.$el.swiper.slidePrev()
  }
}
