
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { NotificationType } from '@alao-frontend/ui/src/lib-components/AlaoNotification/types'
import IconWhatsapp from '~/assets/icons/icon-whatsapp-grey.svg?inline'
import IconFacebook from '~/assets/icons/icon-facebook-grey.svg?inline'
import IconMessenger from '~/assets/icons/icon-messenger-grey.svg?inline'
import IconCopyClipboard from '~/assets/icons/icon-share-grey.svg?inline'
import { Throttle } from '~/utils/decorators/throttle'
@Component({
  components: {
    IconWhatsapp,
    IconFacebook,
    IconMessenger,
    IconCopyClipboard,
  },
})
export default class SaveEmailForm extends Vue {
  siteHref = this.$config.BASE_URL.concat(this.$route.path)
  get facebookHref () {
    return `https://www.facebook.com/sharer/sharer.php?u=${this.siteHref}`
  }

  get whatsappHref () {
    return `https://api.whatsapp.com/send?text=${this.siteHref}`
  }

  get messengerHref () {
    return `https://m.me/?ref=${this.siteHref}`
  }

  @Throttle(1500)
  handleCopyButton () {
    navigator.clipboard.writeText(this.siteHref).then(() => {
      this.$notify({
        title: this.$t('offerCard.share.clipboardTitle') as string,
        message: this.$t('offerCard.share.clipboardText') as string,
        duration: 2000,
        type: NotificationType.SUCCESS,
      })
    })
  }
}
