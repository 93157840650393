
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { OfferBenefitsUtils } from '~/utils/offer-benefits.utils'
import { OnCreated } from '~/core/vue.types'
import { OfferCommonUtils } from '~/utils/offer/offer-common.utils'

@Component
export default class BenefitsMobile extends Vue implements OnCreated {
  @Prop({ required: true, type: Object }) offer!: any
  @Prop({ required: true, type: Number }) discountedActivationPrice!: number

  private offerBenefitsUtils!: OfferBenefitsUtils
  private offerCommonUtils!: OfferCommonUtils

  get isHasActivationDiscount () {
    return Number(this.offer.mobile_offer.activation_costs) !== this.discountedActivationPrice
  }

  get network () {
    const { network: networkName, name: providerName } = this.offer.mobile_offer.provider
    if (!networkName || networkName === providerName) { return null }

    return networkName
  }

  created () {
    this.offerBenefitsUtils = new OfferBenefitsUtils(this.offer, this.$i18n, this.$i18n.locale)

    this.offerCommonUtils = new OfferCommonUtils(this.offer)
  }
}
