
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import Loader from '~/components/Loader.vue'

@Component({
  components: {
    Loader,
  },
})
export default class SaveEmailForm extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly isLoading!: boolean

  private emailValue = ''

  private async onFormSubmit () {
    if (await this.$validator.validate()) {
      this.$emit('submit', this.emailValue)
      this.emailValue = ''
      await this.$nextTick()
      this.vErrors.clear()
    }
  }
}
