
import { Component, namespace, Vue } from 'nuxt-property-decorator'
import clientsWithFeedbackList from './feedback.json'
import { IFeedbackUser } from './types'
import { CommonState, namespace as commonStore } from '~/store/modules/Common'
import LoadMoreButton from '~/components/snippets/LoadMoreButton.vue'
import ClientsFeedbackList from '~/components/sections/clients-feedback/ClientsFeedbackList.vue'

const CommonStore = namespace(commonStore)

const maxCurrentListItems = 9

@Component({
  components: {
    LoadMoreButton,
    ClientsFeedbackList,
  },
})
export default class ClientsFeedback extends Vue {
  @CommonStore.State('totalOrders')
  readonly totalOrders!: CommonState['totalOrders']

  private clients: IFeedbackUser[] = []

  fetch () {
    this.clients = clientsWithFeedbackList
      .sort(() => Math.random() - 0.5)
      .slice(0, maxCurrentListItems)
  }
}
