import throttle from 'lodash/throttle'

export function Throttle (delay: number) {
  return (target: any, prop: string) => {
    return {
      configurable: true,
      enumerable: false,
      value: throttle(target[prop], delay),
    }
  }
}
