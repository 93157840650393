
import { Component, Vue } from 'nuxt-property-decorator'
import VueSlickCarousel from 'vue-slick-carousel'
import MembersItem from '~/components/sections/team/MembersItem.vue'

@Component({
  components: {
    VueSlickCarousel,
    MembersItem,
  },
})
export default class MembersList extends Vue {
  private settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerPadding: '0px',
    centerMode: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1210,
        settings: {
          slidesToShow: 3,
          centerMode: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  }
}
