
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { PriceExplanationBenefit } from '@alao-frontend/core'
import { OfferPriceExplanationFormula } from '~/core/offer.types'
import PriceExplanationDescription from '~/components/offers/PriceExplanationDescription.vue'
import { FeatureFlags } from '~/core/config/feature-flags'

@Component({
  components: {
    PriceExplanationDescription,
  },
})
export default class PriceExplanation extends Vue {
  @Prop({ required: true, default: 0, type: Number })
  readonly price!: number

  @Prop({ required: true, default: 0, type: Number })
  readonly effectivePrice!: number

  @Prop({ required: true, default: 0, type: Number })
  readonly contractDuration!: number

  @Prop({ required: true, default: 0, type: Number })
  readonly savings!: number

  @Prop({ required: true, default: [], type: Array })
  readonly benefits!: PriceExplanationBenefit[]

  readonly featureAmpOffers = this.$featureFlags.isEnabled(FeatureFlags.AMP_COMPARATOR)

  get formula (): OfferPriceExplanationFormula {
    return {
      price: this.price,
      effectivePrice: this.effectivePrice,
      contractDuration: this.contractDuration,
      savings: this.savings,
    }
  }
}
