
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ExpertTipPrependIcon from './ExpertTipPrependIcon.vue'

@Component({
  components: {
    ExpertTipPrependIcon,
  },
})
export default class ExpertTip extends Vue {
  @Prop({ required: true, type: String })
  readonly title!: string

  @Prop({ required: true, type: String })
  readonly bgColor!: string

  @Prop({ required: true, type: String })
  readonly textColor!: string

  @Prop({ required: false, default: null, type: String })
  readonly prependIcon!: string | null

  get style () {
    return {
      backgroundColor: this.bgColor,
      color: this.textColor,
    }
  }
}
