
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class PriceExplanationDescription extends Vue {
  @Prop({ required: true, default: 0, type: Number })
  readonly price!: number

  @Prop({ required: true, default: 0, type: Number })
  readonly effectivePrice!: number

  @Prop({ required: true, default: 0, type: Number })
  readonly contractDuration!: number

  @Prop({ required: true, default: 0, type: Number })
  readonly savings!: number
}
