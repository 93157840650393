
import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class MembersItem extends Vue {
  @Prop({ required: true }) readonly name!: string
  @Prop({ required: true }) readonly position!: string
  @Prop({ required: true, default: 'static' }) readonly type!: string

  get memberCardType (): string {
    return `member-card--${this.type}`
  }
}
