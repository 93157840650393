
import { Component, namespace, Vue } from 'nuxt-property-decorator'
import VueSlickCarousel from 'vue-slick-carousel'
import { CommonState, namespace as commonStore } from '~/store/modules/Common'

const CommonStore = namespace(commonStore)

@Component({
  components: {
    VueSlickCarousel,
  },
})
export default class HappyClients extends Vue {
  @CommonStore.State('totalOrders')
  readonly totalOrders!: CommonState['totalOrders']

  private settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: '0px',
    centerMode: true,
    swipe: false,
    swipeToSlide: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipe: true,
          swipeToSlide: true,
          infinite: true,
          dots: false,
        },
      },
    ],
  }
}
