
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ProviderScore, ProviderScoreType } from '~/core/offer.types'
import RatingBar from '~/components/sections/rating/RatingBar.vue'

@Component({
  components: {
    RatingBar,
  },
})
export default class RatingServices extends Vue {
  @Prop({ required: true }) readonly providerScores!: ProviderScore[]

  get services () {
    return Object.keys(ProviderScoreType).map((type: string) => {
      const serviceType = type as keyof typeof ProviderScoreType
      const translationKey = `provider.rating.service.${type}`

      return {
        label: this.$t(translationKey),
        score: this.providerScores.find((item: ProviderScore) => {
          return item.name === ProviderScoreType[serviceType]
        })?.score,
      }
    })
  }
}
