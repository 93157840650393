
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class HowItWorksCards extends Vue {
  @Prop({ required: true, default: 1 }) readonly amount!: number

  get wrapperClassName () {
    return `hiw-cards--items-${this.amount}`
  }
}
