var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"offer-benefits",class:{
    'offer-benefits--activation_hardcoded': _vm.offerCommonUtils.isHardCodedFreeActivationMobile || _vm.offerCommonUtils.isHardCodedFreeActivationDevice,
  }},[(_vm.network)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerCard.networkFrom', { name: _vm.network }))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isUnlimitedUltraHighSpeedData)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.unlimitedUltraHighSpeedData'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isUnlimitedHighSpeedData)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.unlimitedHighSpeedData'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isUnlimitedMediumSpeedData)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.unlimitedMediumSpeedData'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isUnlimitedLowSpeedData)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.unlimitedLowSpeedData'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isLimitedHighSpeedData)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v(" "),(Number(_vm.offer.mobile_offer.data) >= 1)?[_vm._v("\n      "+_vm._s(_vm.$t('offerDetailsBenefits.gbHighSpeedData', {
          data: Number(_vm.offer.mobile_offer.data),
        }))+"\n    ")]:[_vm._v("\n      "+_vm._s(_vm.$t('offerDetailsBenefits.mbHighSpeedData', {
          data: _vm.$options.filters.gbToMb(Number(_vm.offer.mobile_offer.data)),
        }))+"\n    ")]],2):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isSharedData)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.gbHighSpeedDataCHEU', {
        data: Number(_vm.offer.mobile_offer.shared_data),
      }))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isUnlimitedCallsCHEU)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.unlimitedCallsInCHEU'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isUnlimitedCallsCHOnly)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.unlimitedCallsInCH'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isUnlimitedCallsCHPlusLimitedEU)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.unlimitedCallsCHPlusLimitedEU', {
        minutes: _vm.offer.mobile_offer.call_minutes_to_eu,
      }))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isLimitedCallsCH)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.perMinuteRateMinutes', {
        minutes: _vm.offer.mobile_offer.call_minutes_to_ch,
      }))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isPerMinuteCallsEverywhere)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.perMinuteRate'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isRoamingDataInMB)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.roamingDataMB', {
        data: _vm.$options.filters.gbToMb(Number(_vm.offer.mobile_offer.roaming_data)),
      }))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isRoamingDataInGB)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.roamingInEU', {
        data: Number(_vm.offer.mobile_offer.roaming_data),
      }))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isRoamingUnlimitedData)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.unlimitedRoamingDataInEU'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isRoamingUnlimitedCalls)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.unlimitedCallsInEU'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isRoamingLimitedCalls)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.limitedCallsInEU', {
        calls: _vm.offer.mobile_offer.roaming_call_minutes,
      }))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isTVAppNeoFeature)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    TV app Neo\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isTVAppNeoLightFeature)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    TV app Neo light\n  ")]):_vm._e(),_vm._v(" "),_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.contractLength', { months: _vm.offer.mobile_offer.contract_duration }))+"\n  ")]),_vm._v(" "),_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.activationCost'))+":\n    "),(_vm.isHasActivationDiscount)?[_c('span',{staticStyle:{"white-space":"nowrap"}},[_c('strike',[_vm._v("CHF "+_vm._s(_vm._f("formatPrice")(_vm.offer.mobile_offer.activation_costs)))]),_vm._v(" "),_c('span',{staticClass:"activation-discounted"},[_vm._v("CHF "+_vm._s(_vm._f("formatPrice")(_vm.discountedActivationPrice)))])],1)]:[_vm._v("\n      CHF "+_vm._s(_vm._f("formatPrice")(_vm.offer.mobile_offer.activation_costs))+"\n    ")]],2),_vm._v(" "),(_vm.offerBenefitsUtils.isGreatForGamesAndNetflix)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.greatForGamesAndNetflix'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isGreatForWhatsApp)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.greatForWhatsApp'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.offerBenefitsUtils.isGreatForSocialMedia)?_c('li',[_c('span',{staticClass:"icon-check"},[_vm._v("✓")]),_vm._v("\n    "+_vm._s(_vm.$t('offerDetailsBenefits.greatForSocialMedia'))+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }