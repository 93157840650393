
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class LoadMoreButton extends Vue {
  @Prop({ required: false, default: false, type: Boolean })
  private readonly isLoading!: boolean

  @Emit('click')
  onClick () {
    return true
  }
}
