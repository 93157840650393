
import { Component, Vue } from 'nuxt-property-decorator'
import SpecialOffersItem from '~/components/sections/special-offers/SpecialOffersItem.vue'
import { OnCreated, OnMounted } from '~/core/vue.types'
import { $api } from '~/plugins/axios'

@Component({
  components: {
    SpecialOffersItem,
  },
})
export default class SpecialOffersList extends Vue implements OnCreated {
  offersList = []

  async created (): Promise<void> {
    try {
      this.offersList = await $api.OfferService.recommendedOffers()
    } catch (e) {
      console.error('Unable to load recommended offers')
      this.$sentry.captureException(e)
    }
  }
}
