
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { OfferBenefitsUtils } from '~/utils/offer-benefits.utils'
import { OnCreated, OnMounted } from '~/core/vue.types'

@Component
export default class SpecialOffersItem extends Vue implements OnCreated {
  @Prop({ required: true }) readonly offer: any

  private offerBenefitsUtils!: OfferBenefitsUtils

  get title () {
    return this.offer.mobile_offer.title
  }

  created () {
    this.offerBenefitsUtils = new OfferBenefitsUtils(this.offer, this.$i18n, this.$i18n.locale)
  }

  get finalPrice () {
    return this.offer.final_price
  }

  get regularPrice () {
    return this.offer.regular_price
  }
}
