
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class RatingBar extends Vue {
  @Prop({ required: true, default: 0 }) score!: number

  get roundedScore () {
    return Math.round(this.score)
  }

  get fillStyle () {
    const percent = this.roundedScore * 20
    return {
      width: `${percent}%`,
    }
  }
}
