
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class FaqItem extends Vue {
  private id = `collapse-${(~~(Math.random() * 1e8)).toString(16)}`

  @Prop({ required: true }) readonly title!: string
  @Prop({ type: Boolean, default: false }) readonly dark!: boolean

  onChange (event: Event) {
    const target = event.target as HTMLInputElement
    if (target?.checked) {
      this.$analytics.gtm.sendFAQEvent(this.title)
    }
  }
}

