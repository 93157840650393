
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ExpertTipIconBase from './ExpertTipIconBase.vue'

@Component({
  components: {
    ExpertTipIconBase,
  },
})
export default class ExpertTipPrependIcon extends Vue {
  @Prop({ required: false, default: null, type: String })
  readonly iconUrl!: string | null

  get src () {
    return this.iconUrl || require('~/assets/icons/icon-alao-exclusive.svg')
  }
}
